import { googleDynamicMarketingAddToCart, googleDynamicMarketingProductList } from './googleDynamicMarketing';
import { BADGETYPE } from '/Foundation/React/code/Utilities/BadgeType';

window.google = window.google || {};

google.tagmanager = {
  add: function (valueArray) {
    try {
      dataLayer.push(valueArray);
    } catch (exception) {
      //console.log(exception);
    }
  },

  events: {
    addToCart: function (item) {
      var data = google.tagmanager.product.getProductData(item);
      google.tagmanager.events.addToCartFromData(data);
      if (data) {
        googleDynamicMarketingAddToCart(data);
      }
    },

    addToCartFromBuyersGuide: function (item) {
      var data = google.tagmanager.product.getFromProductRecommendationItem(item);
      google.tagmanager.events.addToCartFromData(data);
    },

    addToCartFromData: function (data) {
      if (!data) return;
      var products = [];

      if (data.product) {
        products = [data.product];
      } else if (data.products) {
        products = data.products;
      }

      google.tagmanager.add({
        event: `EEaddToCart${data.type ? '_' + data.type : ''}`,
        ecommerce: {
          currencyCode: data.currency,
          add: {
            products: products,
          },
        },
      });
    },

    removeFromCart: function (products, currency) {
      google.tagmanager.add({
        event: 'EEremoveFromCart',
        ecommerce: {
          currencyCode: currency,
          remove: {
            products: products,
          },
        },
      });
    },

    openConisualizer: function (conisualizer) {
      google.tagmanager.add({
        event: 'conisualizer',
        action: 'conisualizer open',
        label: conisualizer,
      });
    },

    clickOnReadMore: function (button) {
      var data = google.tagmanager.product.getProductData(button);

      var products = [data.product];

      google.tagmanager.add({
        event: 'EEreadMore',
        ecommerce: {
          currencyCode: data.currency,
          remove: {
            products: products,
          },
        },
      });
    },

    continueToCheckout: function (products) {
      google.tagmanager.add({
        event: 'EEcheckout',
        ecommerce: {
          checkout: {
            actionField: { step: 1 },
            products: products,
          },
        },
      });
    },

    productPageViewed: function (item) {
      var data = google.tagmanager.product.getProductData(item);
      googleDynamicMarketingProductList('view_item');
      google.tagmanager.add({
        event: 'EEproductPageView',
        ecommerce: {
          currencyCode: data.currency,
          apluscontent: dataLayer && dataLayer.length > 0 ? dataLayer[0].AplusContent : false,
          detail: {
            actionField: { list: data.listname },
            products: [data.product],
          },
        },
      });
    },

    clickOnProduct: function (item) {
      var data = google.tagmanager.product.getProductData(item);
      google.tagmanager.add({
        event: 'EEproductClick',
        ecommerce: {
          currencyCode: data.currency,
          click: {
            actionField: { list: data.listname },
            products: [data.product],
          },
        },
      });
    },

    clickOnComponentOnStartPage: function (data) {
      google.tagmanager.add({
        event: 'visualNavigation',
        clickedComponent: data.clickedComponent,
        componentName: data.componentName,
        componentType: data.componentType,
        componentId: data.componentId,
        clickedurl: data.clickedurl,
      });
    },

    numberOfProductSearchResult: function (hits) {
      var values = {
        searchResults: +hits,
        event: 'productHitsInSearch',
      };
      google.tagmanager.add(values);
    },

    placeAReturn: function (orderId, products) {
      google.tagmanager.add({
        event: 'EErefund',
        ecommerce: {
          refund: {
            actionField: {
              id: orderId,
            },
            products: products,
          },
        },
      });
    },

    loginUser: function (retailerErpId) {
      google.tagmanager.add({
        event: 'login',
        dealerID: retailerErpId,
      });
    },

    navigatingMyAccount: function (label, section) {
      google.tagmanager.add({
        event: 'myAccount',
        accountSection: section,
        labelName: label,
      });
    },
  },

  product: {
    getProductData: function (item) {
      if (item == null) return null;

      var isProductPage = item.id === 'product-view';
      var isListProduct = item.closest('article')?.classList.contains('product-list-item');
      var isSliderProduct = item.closest('.productSliderItem');
      var isBundleFromDummyProduct = item.closest('.bundle-flyout');
      var isAddToCart = item.classList.contains('tag-manager-add-to-cart-button');
      var isUpsell = item.classList.contains('accessory-atc-button');

      if (isProductPage) {
        return google.tagmanager.product.getFromProductPage(item);
      } else if (isListProduct) {
        return google.tagmanager.product.getFromListProduct(item);
      } else if (isSliderProduct) {
        return google.tagmanager.product.getFromSliderProduct(isSliderProduct);
      } else if (isAddToCart) {
        if (isBundleFromDummyProduct) return google.tagmanager.product.getFromBundleModalProductItem(item);
        if (isUpsell) return google.tagmanager.product.getFromUpsellAccessory(item);
        return google.tagmanager.product.getFromAddToCartButton(item);
      }
    },

    getFromProductPage: function (item) {
      let data = item.querySelector('.hidden.analytics-data').dataset;
      let currency = item.querySelector('.product-detail__buy-area .currencycode')?.innerText.trim() || '';
      let price = item.querySelector('.product-detail__buy-area .ga-price')?.innerText.trim() || '';
      let discountPrice = item.querySelector('.product-detail__buy-area .ga-discount-price')?.innerText.trim() || '';

      var product = {
        id: data.pimid,
        name: data.name,
        price: price,
        metric2: discountPrice,
        brand: google.tagmanager.getBrand(),
        category: google.tagmanager.parseCategoryFromUrl(window.location.href),
        ...google.tagmanager.getBadgesData(item),
      };

      return {
        currency: currency,
        listname: data.listname,
        product: product,
      };
    },

    getFromAddToCartButton: function (item) {
      var buyArea = item.closest('.product-detail__buy-area');
      let data = buyArea.querySelector('.hidden.analytics-data')?.dataset;
      let currency = buyArea.querySelector('.currencycode')?.innerText?.trim();
      let price = buyArea.querySelector('.ga-price')?.innerText?.trim();
      let discountPrice = buyArea.querySelector('.ga-discount-price')?.innerText?.trim();

      var product = {
        id: data.pimid,
        name: data.name,
        price: price,
        metric2: discountPrice,
        brand: google.tagmanager.getBrand(),
        category: google.tagmanager.parseCategoryFromUrl(window.location.href),
      };

      let type;
      if (data.isThuleBundle === 'True' && item.classList.contains('sticky')) type = 'bundle_sticky';
      else if (data.isThuleBundle === 'True') type = 'bundle';
      else if (item.classList.contains('sticky')) type = 'sticky';
      else type = 'pdp';

      return {
        currency: currency,
        listname: data.listname,
        product: product,
        type: type,
      };
    },

    getFromUpsellAccessory: function (item) {
      var accessory = item.closest('.accessory');
      let data = accessory.querySelector('.hidden.analytics-data')?.dataset;
      let currency = document.querySelector('.currencycode')?.innerText?.trim();

      var product = {
        id: data.pimid,
        name: data.name,
        price: data.price,
        brand: google.tagmanager.getBrand(),
        category: google.tagmanager.parseCategoryFromUrl(window.location.href),
      };

      return {
        currency: currency,
        product: product,
        type: 'upselling',
      };
    },

    getFromSliderProduct: function (item) {
      var analyticsDataElems = item.querySelectorAll('.analytics-data');

      if (analyticsDataElems.length > 1) {
        var products = [];
        analyticsDataElems.forEach((analyticsElem) => {
          var data = analyticsElem.dataset;
          products.push({
            id: data.pimid,
            name: data.name,
            price: data.price,
            discountPrice: data.discountPrice,
            brand: google.tagmanager.getBrand(),
            category: google.tagmanager.parseCategoryFromUrl(data.link),
            position: data.position,
          });
        });

        return {
          currency: data.currency,
          listname: data.listname,
          products: products,
        };
      } else {
        var data = {};
        if (analyticsDataElems[0]) {
          data = analyticsDataElems[0].dataset;
        }
        const productUrl = item.querySelector('a.tag-manager-product-slider-item')?.href;
        var product = {
          id: data.pimid,
          name: data.name,
          price: data.price,
          metric2: data.discountPrice,
          brand: google.tagmanager.getBrand(),
          category: google.tagmanager.parseCategoryFromUrl(productUrl),
        };

        return {
          currency: data.currency,
          listname: data.listname,
          product: product,
        };
      }
    },

    getFromListProduct: function (item) {
      item = item.closest('article');
      var currency = google.tagmanager.product.getCurrency();

      var data = item.querySelector('.d-none.analytics-data').dataset;
      data.category = google.tagmanager.product.getCategory(item, data);
      currency = google.tagmanager.product.getCurrency(item);
      var priceItem = item.querySelector('.d-none.ga-price');
      var price = '';
      if (priceItem != null) price = priceItem.innerHTML.trim();
      var discountPriceItem = item.querySelector('.d-none.ga-discount-price');
      var discountPrice = '';
      if (discountPriceItem != null) {
        discountPrice = discountPriceItem.innerHTML.trim();
      }

      var product = {
        id: data.pimid,
        name: data.name,
        price: price,
        metric2: discountPrice,
        brand: google.tagmanager.getBrand(),
        category: data.category,
        ...google.tagmanager.getBadgesData(item),
      };

      if (data.position) product.position = data.position;

      return {
        currency: currency,
        listname: data.listname,
        product: product,
      };
    },

    getFromBundleModalProductItem: function (item) {
      var analyticsDataElems = item.closest('.bundle-item')?.querySelectorAll('.hidden.analytics-data');
      var products = [];
      analyticsDataElems.forEach((analyticsElem) => {
        var data = analyticsElem.dataset;

        products.push({
          id: data.pimid,
          name: data.name,
          price: data.price,
          discountPrice: data.discountPrice,
          brand: google.tagmanager.getBrand(),
          category: google.tagmanager.parseCategoryFromUrl(data.link),
        });
      });

      return {
        currency: analyticsDataElems[0].dataset.currency,
        products: products,
        type: 'bundle',
      };
    },

    getCategory: function (item, data) {
      var link = item.querySelector('a.product-link');
      if (item.tagName == 'A' && item.classList.contains('product-link')) link = item;
      if (link == null) link = window.location;

      if (!data.category || data.category == 'No category found') {
        var productType = document.getElementById('ProductType');
        if (productType) {
          var productTypeValue = productType.val();
          if (productTypeValue === 'Spare part') {
            return 'Spare parts';
          } else {
            return google.tagmanager.parseCategoryFromUrl(link.href);
          }
        } else {
          return google.tagmanager.parseCategoryFromUrl(link.href);
        }
      } else {
        return data.category;
      }
    },
    getCurrency: function (item) {
      var currencyItem = document.querySelector('.d-none.currencycode');
      if (item) {
        currencyItem = item.querySelector('.d-none.currencycode');
      }
      var currency = '';
      if (currencyItem != null) currency = currencyItem.innerHTML.trim();
      return currency;
    },

    getFromProductRecommendationItem: function (item) {
      var name = item.querySelector('.product-name a').innerHTML.trim();
      var pimid = item.dataset.pimid;
      var price = '';
      try {
        price = item.querySelector('.hidden.ga-price').innerText.trim();
      } catch (ex) {}

      var discountPrice = '';
      try {
        discountPrice = item.querySelector('.hidden.ga-discount-price').innerText.trim();
      } catch (ex) {}

      var currencyItem = item.querySelector('.hidden.currencycode');
      var currency = '';
      if (currencyItem.length > 0) currency = currencyItem.innerHTML.trim();
      var link = item.querySelector('.product-name a');

      var category = google.tagmanager.parseCategoryFromUrl(link[0].pathname);

      var product = {
        id: pimid,
        name: name,
        price: price,
        discountPrice: discountPrice,
        brand: google.tagmanager.getBrand(),
        category: category,
      };

      return {
        currency: currency,
        listname: 'Buyers Guide',
        product: product,
      };
    },
  },

  getBadgesData: (item) => {
    let badgeList = [];
    const dataBadges = item.querySelectorAll('[data-badgetype]');
    dataBadges.forEach((badge) => {
      const badgetype = badge.getAttribute('data-badgetype');
      if (badgetype) {
        badgeList.push(badgetype);
      }
    });
    let badgeData = {
      badges: badgeList.length > 0,
      badgesInformative: badgeList.find((x) => x === BADGETYPE.INFORMATIVE) != null,
      badgesCommercial: badgeList.find((x) => x === BADGETYPE.COMMERCIAL) != null,
      badgesFeature: badgeList.find((x) => x === BADGETYPE.FEATURES) != null,
    };
    return badgeData;
  },

  parseCategoryFromUrl: function (path) {
    /*
     * Just nu kommer paths in i något av följande format
     * /språk/huvudkategori/subkategori/produkt
     * Där subkategori och produkt inte alltid blir inskickade beroende på vilken sida vi är på.
     * Det är subkategorin vi vill åt. Därför kommer jag skriva kod som går hårt mot 3 platsen i arrayen efter en split. Om arrayen inte är så stor så testar vi ta huvudkategorin annars skickar vi in tomt.
     */

    var categoryAsString = 'No category found';
    if (!path) return categoryAsString;
    var splittedString = path.split('/');

    if (document.getElementById('product-view') != null) {
      if (splittedString.length < 6) {
        //If we go to the product page from e.g. Google Shopping, the URL
        //is shortened and doesn't contain the category. We can then try to get
        //the category from the breadcrumbs
        const breadcrumbs = Array.from(document.querySelectorAll('.breadcrumbs a'));
        if (breadcrumbs && breadcrumbs.length > 1) {
          const categoryCrumb = breadcrumbs[breadcrumbs.length - 1];
          if (categoryCrumb) {
            const splittedString = categoryCrumb.href?.split('/');
            categoryAsString = splittedString[splittedString.length - 1];
          }
        }
      } else {
        // Om vi är på produktsidan och URLen inte är förkortad så är subkategorin alltid andra från slutet i arrayen
        // Exempel: /en-us/bike-rack/hitch-bike-racks/thule-easyfold-xt-2-_-903202 --> hitch-bike-racks
        // eller /en-us/accessories/thule-carbon-frame-protector-_-1692661 --> accessories
        categoryAsString = splittedString[splittedString.length - 2];
      }
    } else if (splittedString.length === 5) {
      //Använd huvudkategorin
      categoryAsString = splittedString[4];
    } else if (splittedString.length > 6) {
      //Använd subkategorin
      categoryAsString = splittedString[5];
    }
    return categoryAsString;
  },

  getBrand: function () {
    if (window._THULEDATA) {
      return window._THULEDATA.SitecoreSiteName;
    } else if (window._EXTRANETDATA) {
      return window._EXTRANETDATA.SitecoreSiteName;
    }

    return '';
  },

  //formatCategoryString: function (category) {
  //    /*
  //     * Denna metoden är avsedd att ta in kategorin som skickas med i alla AddCartLine anrop och formatera dem till lower-case och mellanslag blir bindestreck.
  //     * Anledningen är att vid vissa tillfällen läser vi kategorin från URL och då är datan redan lowercase och med bindestreck.
  //     * Detta innebär att alla kategorier som kommer inte via AddCartLine bör vara formaterade likadant.
  //     */

  //    var formattedString = category.toLowerCase().trim().replace(' ', '-');

  //    return formattedString;
  //}
};
